#root {
  background-color: #f5f5f5;
}

header {
  background-color: #282c34;

  .App-header {
    min-width: 690px;
    max-width: 1980px;
    margin: auto;
    height: 8vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    position:  relative;

    h1{
      font-size: 2.5rem;

      @media only screen and (max-width: 1000px) {
        font-size: 2rem;
      }
      @media only screen and (max-width: 800px) {
        font-size: 1.7rem;
      }
    }

    .logo-right{
      position: absolute;
      top: auto;
      right: 2vh;

      .logo-right-img{
        height: 1.5em;
        width: auto;
      }
    }

    .logo-left{
      position: absolute;
      top: auto;
      left: 2vh;

      .logo-left-img{
        width: auto;
        height: 1.3em;
        vertical-align: top;
      }

      .how-to{
        font-size: 0.5em;
        padding-left: 3vw;

        a {
          color: #a1a1a1;
        }
        a:hover{
          color: inherit;
        }
      }
    }

}}

.App {
  text-align: center;
  background-color: #f5f5f5;
  min-width: 690px;
  max-width: 1980px;
  margin: auto;


  .main{
     min-height: 90vh;

     .main_loading{
       text-align: center;
       padding-top: 40vh;
     }

    .alert{
      width: 100%;
    }

    .section-title {
      text-align: center;
      margin-bottom: 20px;
    }

    .list-of-courses{
      text-align: left;
      padding-top: 2vh;
      padding-bottom: 2vh;
      height: 90vh;
      padding-right: .8vh;
      // margin-right: .1vh;
      margin: auto;
      min-width: 260px;
      // max-width: 550px;

      .content {
        height: 94%;
        padding: 1.5vh 1vh 1.5vh 1vh;
        background-color: #fff;
        border: 1px solid #c1c1c1;
        border-radius: 5px;
        box-shadow: 6px 6px 10px 0 hsla(0,0%,74.1%,.4);

        .content-scroll {
          height: 100%;
          padding-right: 10x;
          padding-bottom: 7px;
          overflow-x: hidden;
          overflow-y: scroll;

          .main-title {
            text-align: center;
            margin-bottom: 17px;
          }

          .main-title.second {
            margin-top: 40px;
          }

          .semester-title {
            text-align: center;
            font-weight: normal;
            font-size: 0.75em;
            color: gray;
          }
          .buttons-container{
            text-align: center;
            padding: 8px 7px 25px 7px;

            .btn {
              padding: 2px 6px;
              font-size: 0.75em;
              margin: 6px;
            }

            #importCourse {
              display:none;
            }
          }
          .col {
            padding: 0px;
            min-width: 215px;
            max-width: 300px;
            margin: 0 auto;
          }

          .col:nth-child(2) {
             // border-left: 2px solid #f0f0f0;
          }

        }
      }

      .content-scroll::-webkit-scrollbar-track
      {
        -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.9);
        border-radius: 5px;
        background-color: #F5F5F5;
      }

      .content-scroll::-webkit-scrollbar
      {
        width: 5px;
      }

      .content-scroll::-webkit-scrollbar-thumb
      {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.3);
        background-color: #999;
      }

    }

    .time-table{
      text-align: left;
      padding-top: 2vh;
      padding-bottom: 2vh;
      height: 90vh;
      padding-right: .8vh;
      // margin-right: .1vh;
      margin: auto;
      min-width: 260px;
      // max-width: 550px;

      .content {
        height: 94%;
        padding: 1.5vh 1vh 1.5vh 1vh;
        background-color: #fff;
        border: 1px solid #c1c1c1;
        border-radius: 5px;
        box-shadow: 6px 6px 10px 0 hsla(0,0%,74.1%,.4);

        .content-scroll {
          height: 100%;
          padding-right: 7px;
          padding-bottom: 7px;
          overflow-x: hidden;
          overflow-y: scroll;

          .main-title {
            text-align: center;
            margin-bottom: 17px;
          }

          .main-title.second {
            margin-top: 40px;
          }

          .semester-title {
            text-align: center;
            font-weight: normal;
            font-size: 0.75em;
            color: gray;
          }

          .col {
            padding: 0px;
            min-width: 215px;
            max-width: 300px;
            margin: 0 auto;
          }

          .col:nth-child(2) {
            // border-left: 2px solid #f0f0f0;
          }

          .dropImg{
            width: 100px;
            margin-top: 3px;
          }

          .buttons-container{
            text-align: center;
            padding: 8px 7px 25px 7px;

            .btn {
              padding: 2px 6px;
              font-size: 0.75em;
              margin: 6px;
            }

            #importData {
              display:none;
            }
          }

          .semester-selector {
            margin-bottom: 18px;
            text-align: center;
            font-size: 0.8em;

            label {
              text-align: right;
              // margin-top: 6px;
            }

            select {
              width: 4.3em;
              font-size: 0.9em;
              padding: 0px 11px;
              height: 24px;
              margin-left: 8px;
              margin-bottom: 2px;
            }
          }

        }

        .content-scroll::-webkit-scrollbar-track
        {
          -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.9);
          border-radius: 5px;
          background-color: #F5F5F5;
        }

        .content-scroll::-webkit-scrollbar
        {
          width: 5px;
        }

        .content-scroll::-webkit-scrollbar-thumb
        {
          border-radius: 5px;
          -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.3);
          background-color: #999;
        }
      }
    }

    .statistics{
      text-align: left;
      padding-top: 2vh;
      padding-bottom: 2vh;
      height: 90vh;
      padding-right: .8vh;
      // margin-right: .1vh;
      margin: auto;
      min-width: 350px;
      max-width: 931px;

      .content {
        height: 94%;
        padding: 1.5vh 1vh 1.5vh 1vh;
        background-color: #fff;
        border: 1px solid #c1c1c1;
        border-radius: 5px;
        box-shadow: 6px 6px 10px 0 hsla(0,0%,74.1%,.4);

        .content-scroll {
          height: 100%;
          padding-left: 10px;
          padding-right: 12px;
          padding-bottom: 7px;
          overflow-x: hidden;
          overflow-y: scroll;

          // not working
          thead {
            background-color: #f5f5f5;
          }

          .buttons-container{
            text-align: center;
            padding: 8px 7px 25px 7px;

            .btn {
              padding: 2px 6px;
              font-size: 0.75em;
              margin: 6px;
            }
          }

          .stats-tabs {
            margin-top: 30px;
            margin-bottom: 20px;
          }

          .button-csv-export {
            text-align: center;
            
            .btn {
              padding: 2px 6px;
              font-size: 0.75em;
              margin: 6px auto;
            }
          }

          .badge.beta-light{
            background-color: #f1f1f1;
          }

          h6{
            padding: 40px 0 10px 0;
            text-align: center;
          }

          h6:first-child {
            padding-top: 15px;
          }

          h6.first {
            padding-top: 5px;
          }

          .chart-container{
            max-width: 420px;
            margin: -22px auto 0 auto;

            // .apexcharts-legend.apexcharts-align-center{
            //   top: 12px !important;
            // }

            text, ul {
              font-size: 0.85em;
            }

            .chart-tooltip{
              background-color: white;
              padding: 8px;
              border: 1px solid #cccc;
            }
          }

          .list {
            margin: 0;
            padding-left: 20px;
            font-size: 0.7em;

            li {
              border: 1px solid white;
              transition: 0.2s ease-out;
              border-radius: 2px;
              padding-left: 2px;
            }
          }

          .profile.hover:hover, .list.hover>li:hover {
            border-color: #007bff;
            color: #007bff;
            // cursor: pointer;
          }

          .ects-overview {
            text-align: center
          }

          .ects-topics, .ects-overview {
            font-size: 0.7em;
          }

          .overlap {
            th:first-child {
              width: 50%;
            }
            
            ul {
              padding-left: 20px;
            }
          }

        }

        .empty-data {
          text-align: center;
          margin: 4.4em 0 4em 0;

          .header {
            font-weight: bold;
            font-size: 1.2em;
            margin-bottom: 10px;
          }
        }

        .content-scroll::-webkit-scrollbar-track
        {
          -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.9);
          border-radius: 5px;
          background-color: #F5F5F5;
        }

        .content-scroll::-webkit-scrollbar
        {
          width: 5px;
        }

        .content-scroll::-webkit-scrollbar-thumb
        {
          border-radius: 5px;
          -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.3);
          background-color: #999;
        }

      }

    }

    .visitor-stats {
      text-align: left;
      padding: 2vh;
      height: 90vh;
      margin: auto;
      min-width: 350px;
      max-width: 1300px;

      .content {
        height: 94%;
        padding: 1.5vh 1vh 1.5vh 1vh;
        background-color: #fff;
        border: 1px solid #c1c1c1;
        border-radius: 5px;
        box-shadow: 6px 6px 10px 0 hsla(0,0%,74.1%,.4);

        .content-scroll {
          height: 100%;
          padding-left: 10px;
          padding-right: 12px;
          padding-bottom: 7px;
          overflow-x: hidden;
          overflow-y: scroll;

          .vertical-center {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .chart-container{
            max-width: 420px;
            min-width: 340px;
            margin: auto;

            // .apexcharts-legend.apexcharts-align-center{
            //   top: 12px !important;
            // }
          }

          .table {
            margin-top: 10px;
          }

          h5{
            padding: 40px 0 30px 0;
            text-align: center;
          }

          h5:first-child{
            padding-top: 40px;
          }
        }

        .content-scroll::-webkit-scrollbar-track
        {
          -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.9);
          border-radius: 5px;
          background-color: #F5F5F5;
        }

        .content-scroll::-webkit-scrollbar
        {
          width: 5px;
        }

        .content-scroll::-webkit-scrollbar-thumb
        {
          border-radius: 5px;
          -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.3);
          background-color: #999;
        }
      }
    }

    .course {
      text-align: left;
      padding: 0px 7px;

      .semester{
        font-size: 0.75em;
        font-style: italic;
        color: #c9c9c9;
      }

      .title{
        font-weight: 500;
        padding-top: 2px;
        padding-bottom: 3px;
        font-size: 1.1em;
        line-height: 1.3;
      }

      .credits {
        font-style: italic;
        font-size: 0.75em;
        // color: #a7a7a7
        color: gray;
        margin-top: 3px;
      }

      .area_topics{
        font-size: 0.75em;
        color: gray;

        tr {
          color: #c1c1c1;
          vertical-align: top;

          td{
            padding: 0;
          }
        }

      }

      .leftBtn {
        float: right;
        margin-top: -5px;
        margin-right: 4px;
        cursor: default;

        svg {
          font-size: 0.9rem;
          color: lightsalmon;
        }
      }

      .middleBtn {
        cursor: pointer;
        float: right;
        margin-top: -5px;
        // margin-right: -7px;

        svg {
          font-size: 0.8rem;
        }
      }

      .middleBtn:hover svg {
        color: #007acc;
      }

      .rightBtn {
        cursor: pointer;
        float: right;
        margin-top: -5px;
        margin-right: -7px;

        svg {
          font-size: 1rem;
        }
      }

      .rightBtn:hover svg {
        color: indianred;
      }
    }

  }

  .todo{
    font-weight: lighter;
    font-style: italic;
    color: grey;
    text-align: center;
  }

}

footer {
  background-color: #282c34;

  .App-footer {
    color: rgb(200, 200, 200);
    min-width: 690px;
    max-width: 1980px;
    margin: auto;
    height: 2vh;
    font-size: 0.65em;
    clear: both;
    position: relative;

    p{
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translateY(-55%);
      margin: 0;
      text-align: center;
    }
  }
}

.modal-add-course{

  .modal-header{
    background-color: lightskyblue;
  }

  .modal-header.edit{
    background-color: #ffc107;
  }

  .modal-footer{
    background-color: #d8d8d8;

    .left {
      margin-right: 40px;
    }
  }

  .row .form-label:first-child{
    text-align: right;
    padding-right: 1.2em;
  }

  .vertical{
    display: flex;
    align-items: center;

    label{
      margin: auto 0;
    }
  }

  .center{
    display: flex;
    justify-content: center;
  }

  .badge {
    margin: 0 3px;
  }

  .modal-badge {
    padding: 0.4em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    max-width: 100%;
    border-radius: 0.25rem;
    margin: 0.05em 0;
    color: white;
    // background-color: #007bff;

    &.ecsf {
      background-color: rgb(250,250,250);
      border: 1px solid grey;
      color: black;
      font-weight: normal;
      margin: 0.1em 0;
    }
  }

  .sel-option{
    font-size: 0.8em;
  }

  svg.deleteIcon, svg.editIcon {
    margin-left: 7px;
    margin-top: -1px;
    //color: #c5c5c5;
    cursor: pointer;
    font-size: 1rem;
  }

  .deleteIcon:hover{
    color: indianred;
  }

  .editIcon:hover {
    color: #007acc;
  }
  .editIcon.disabled:hover {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.54);
  }

  .percents {
    padding-left: 0px;
    padding-right: 5px;
  }

  #currentTopicPer, #currentSkillPer, #currentKnowledgePer {
    padding-left: 5px;
    padding-right: 4px;
  }

  .topicListPer {
    font-size: 0.8em;
    text-align: right;
    padding-bottom: 0.25em;
    padding-right: 0.4em;
  }

  span.topicListPer {
    padding-right: 0.8em;
  }

  .percent-checksum {
    margin-bottom: 1.5rem;

    span.invalid {
      border: 1px solid #dc3545;
      padding: 6px 16px;
      border-radius: 0.25rem;
    }
  }

}

.modal-feedback {

  .modal-header{
    // background-color: tan;
    background-color: wheat;
  }

  .modal-body {
    text-align: center;
    margin: 20px;

    .btn {
      margin: 10px;
    }
  }


}

// statistics - tooltip

.tooltip {
  visibility: visible !important;
  transition-duration: 0.15s !important;
  max-width: 900px;

  h6 {
    padding-top: 18px !important;
    color: #9acbff;
  }

  ul{
    text-align: left;
    padding-left: 20px;
    line-height: 1.35;

    li{
      margin-top: 4px;
    }
  }
}

.tooltip.place-top {
  margin-top: -6px !important;
}

.tooltip.show {
  opacity: 0.97 !important;
}

.Cell {
  text-align: center;
}

@import '~bootstrap/scss/bootstrap.scss';
